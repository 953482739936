import { Component, OnInit, Inject } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { LayoutUtilsService, LoaderService } from "src/app/shared";
import { arraysEqual } from "src/app/shared/helpers/functions";
import { PGIService } from "src/app/shared/services/pgi.service";

interface DialogData {
    loggedInUserType: number;
    filters: any;
    activeFilters: any;
    isAdmin: boolean;
    isAgencyAdmin: boolean;
}

@Component({
    selector: 'app-charts-filter-dialog',
    templateUrl: './charts-filter-dialog.component.html',
    styleUrls: ['./charts-filter-dialog.component.scss'],
})
export class ChartsFilterDialogComponent implements OnInit {

    public date: Date = new Date();
    public selected: any = {
        agency_name: 'All Agencies',
        carrier: ['All Carriers'],
        state: ['All States'],
        lob: ['All LOBs'],
        productline: ['All Product Lines'],
        agent: ['All Agents'],
    };
    private previouslySelected: any = {
        agency_name: 'All Agencies',
        carrier: ['All Carriers'],
        state: ['All States'],
        lob: ['All LOBs'],
        productline: ['All Product Lines'],
        agent: ['All Agents'],
    };
    // public allSelected: any = {
    //     agency: true,
    //     carrier: true,
    //     state: true,
    //     lob: true,
    //     productline: true,
    //     agent: true,
    // }
    public dateRangeControl = new FormControl('');
    public startDate = new FormControl('', { validators: this.dateRangeValidator() });
    public endDate = new FormControl('', { validators: this.dateRangeValidator() });

    constructor(private translate: TranslateService, public dialogRef: MatDialogRef<ChartsFilterDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private pgiService: PGIService, private loader: LoaderService, private layoutUtilsService: LayoutUtilsService) {

        this.startDate.setValue(this.data.activeFilters.startDate || '');
        this.endDate.setValue(this.data.activeFilters.endDate || '');
        this.dateRangeControl.setValue(this.data.activeFilters.dateType || '');

        // this.data.filters.agency_name = this.data.filters.agency_name;
        // this.data.filters.agent = this.data.filters.agent;
        // this.data.filters.carrier = this.data.filters.carrier;
        // this.data.filters.state = this.data.filters.state;
        // this.data.filters.productline = this.data.filters.productline;
        // this.data.filters.lob = this.data.filters.lob;

        // this.selected.agency = 'All Agencies';
        this.selected = {
            agency_name: this.data.activeFilters.agency_name || 'All Agencies',
            agent: this.data.activeFilters.agent || ['All Agents'],
            carrier: this.data.activeFilters.carrier || ['All Carriers'],
            state: this.data.activeFilters.state || ['All States'],
            productline: this.data.activeFilters.productline || ['All Product Lines'],
            lob: this.data.activeFilters.lob || ['All LOBs'],
        };

        // if (this.data.activeFilters) {
        this.getFilters();
        // }
        // console.log('this.selected', this.selected)
        // if (this.data.activeFilters.agency.includes('All Agencies')) {
        //     this.selected.agency = [...this.data.filters.agency, 'All Agencies'];
        // }

        // if (this.data.activeFilters.agent.includes('All Agents')) {
        //     this.selected.agent = [...this.data.filters.agent, 'All Agents'];
        // }

        // if (this.data.activeFilters.carrier.includes('All Carriers')) {
        //     this.selected.carrier = [...this.data.filters.carrier, 'All Carriers'];
        // }

        // if (this.data.activeFilters.state.includes('All States')) {
        //     this.selected.state = [...this.data.filters.state, 'All States'];
        // }

        // if (this.data.activeFilters.productline.includes('All Product Lines')) {
        //     this.selected.productline = [...this.data.filters.productline, 'All Product Lines'];
        // }

        // if (this.data.activeFilters.lob.includes('All LOBs')) {
        //     this.selected.lob = [...this.data.filters.lob, 'All LOBs'];
        // }
    }

    ngOnInit(): void {
    }

    private async getFilters(updateAgencies: boolean = false) {
        this.loader.display(true);
        let agency = this.selected.agency_name;
        let lob = this.selected.lob.filter(i => i != 'All LOBs');
        let state = this.selected.state.filter(i => i != 'All States');
        let productline = this.selected.productline.filter(i => i != 'All Product Lines')
        let agent = this.selected.agent.filter(i => i != 'All Agents');
        let carrier = this.selected.carrier.filter(i => i != 'All Carriers');

        // if (agency.length == 0) {
        //     agency = undefined;
        // }

        // if (agent.length && agency.length != 0) {
        //     agent = [];
        // }

        this.pgiService.getPGIOverviewFilter(agency, lob, state, productline, agent, carrier).then((pgiFiltersData: any) => {
            if (pgiFiltersData && pgiFiltersData.status && pgiFiltersData.results) {
                // let agencies: any = updateAgencies ? pgiFiltersData.filters.Agency.concat(this.selected.agency).filter((item, index, array) => array.indexOf(item) === index).filter(i => i != 'All Agencies') : this.allAgencies;
                // let agents: any = pgiFiltersData.filters.Agent.concat(this.selected.agent).filter((item, index, array) => array.indexOf(item) === index).filter(i => i != 'All Agents');
                // let states: any = pgiFiltersData.filters.AgencyState.concat(this.selected.state).filter((item, index, array) => array.indexOf(item) === index).filter(i => i != 'All States');
                // let productLines: any = pgiFiltersData.filters.ProductLine.concat(this.selected.productline).filter((item, index, array) => array.indexOf(item) === index).filter(i => i != 'All Product Lines');
                // let lobs: any = pgiFiltersData.filters.LOB.concat(this.selected.lob).filter((item, index, array) => array.indexOf(item) === index).filter(i => i != 'All LOBs');
                // let carriers: any = pgiFiltersData.filters.CarrierName.concat(this.selected.carrier).filter((item, index, array) => array.indexOf(item) === index).filter(i => i != 'All Carriers');

                this.data.filters = {
                    agency_name: pgiFiltersData.results.filters.Agency,
                    lob: pgiFiltersData.results.filters.LOB,
                    state: pgiFiltersData.results.filters.AgencyState,
                    productline: pgiFiltersData.results.filters.ProductLine,
                    agent: pgiFiltersData.results.filters.Agent,
                    carrier: pgiFiltersData.results.filters.CarrierName,
                };
            }
        }).catch((e) => {
            this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + e, 'Dismiss');
        }).finally(() => this.loader.display(false));
    }

    dateRangeValidator(): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: any } | null => {
            const start = formGroup.get('startDate')?.value;
            const end = formGroup.get('endDate')?.value;
            return start && end && start > end ? { 'invalidDateRange': true } : null;
        };
    }

    applyFilters() {
        this.dialogRef.close({
            agency_name: this.selected.agency_name,
            agent: this.selected.agent,
            carrier: this.selected.carrier,
            state: this.selected.state,
            productline: this.selected.productline,
            lob: this.selected.lob,
            dateType: this.dateRangeControl.value,
            startDate: this.startDate.value,
            endDate: this.endDate.value,
        });
    }

    onSelectionChange(type: string, isOpen: Event) {
        // if (!this.selected[type].includes(value) && this.selected[type].includes(allValue)) {
        //     this.selected[type] = this.selected[type].filter(i => i != allValue);
        // }
        // else if (this.selected[type].length == this.data.filters[type].length && !this.selected[type].includes(allValue)) {
        //     this.selected[type] = [allValue, ...this.data.filters[type]];
        //     this.allSelected[type] = true;
        // }
        if (!isOpen) {
            if (Array.isArray(this.selected[type]) && this.selected[type].length > 10) {
                this.selected[type].pop();
                this.layoutUtilsService.showNotification(this.translate.instant('You can select a max of 10 options.'), this.translate.instant('Dismiss'));
                return;
            }

            if (!arraysEqual(this.previouslySelected[type], this.selected[type])) {
                this.previouslySelected[type] = this.selected[type];
                this.getFilters();
            }
        }
    }

    toggleSelectAll(type: string, valueName: string) {
        // if (!this.allSelected[type]) {
        //     this.selected[type] = [valueName, ...this.data.filters[type]];
        // }
        // else {
        //     this.selected[type] = [];
        // }

        // this.allSelected[type] = !this.allSelected[type];

        // this.getFilters();
    }
}