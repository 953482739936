<div mat-dialog-content>
    <span class="title">{{'Filters' | translate}}</span>
    <div fxLayout="column" fxLayoutGap="1vh" class="filters">
        <div fxFlex *ngIf="data.isAdmin">
            <mat-form-field>
                <mat-label>{{'Agency' | translate}}</mat-label>
                <mat-select [(value)]="selected.agency_name" (openedChange)="onSelectionChange('agency_name', $event)">
                    <!-- <mat-option [value]="'All Agencies'" (click)="toggleSelectAll('agency', 'All Agencies')">
                        All Agencies
                    </mat-option> -->
                    <!-- <mat-option disabled class="no-data" value="" *ngIf="!data.filters?.agency">No -->
                    <!-- Data</mat-option> -->
                    <mat-option *ngFor="let agency of data.filters?.agency_name" [value]="agency"
                        (click)="onSelectionChange('agency_name', agency, 'All Agencies')">
                        {{ agency }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex *ngIf="data.isAdmin || data.isAgencyAdmin">
            <mat-form-field>
                <mat-label>{{'Agent' | translate}}</mat-label>
                <mat-select [(ngModel)]="selected.agent" multiple (openedChange)="onSelectionChange('agent', $event)">
                    <!-- <mat-option (click)="toggleSelectAll('agent', 'All Agents')" [value]="'All Agents'"
                        *ngIf="data.filters?.agent?.length">
                        All Agents
                    </mat-option> -->
                    <mat-option disabled class="no-data" value="" *ngIf="!data.filters?.agent?.length">No
                        Data</mat-option>
                    <mat-option *ngFor="let agent of data.filters?.agent" [value]="agent">{{ agent }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex>
            <mat-form-field>
                <mat-label>{{'Carrier' | translate}}</mat-label>
                <mat-select [(ngModel)]="selected.carrier" multiple
                    (openedChange)="onSelectionChange('carrier', $event)">
                    <!-- <mat-option (click)="toggleSelectAll('carrier', 'All Carriers')" [value]="'All Carriers'"
                        *ngIf="data.filters?.carrier?.length">
                        All Carriers
                    </mat-option> -->
                    <mat-option disabled class="no-data" value="" *ngIf="!data.filters?.carrier?.length">No
                        Data</mat-option>
                    <mat-option *ngFor="let carrier of data.filters?.carrier" [value]="carrier">{{ carrier
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex>
            <mat-form-field>
                <mat-label>{{'State' | translate}}</mat-label>
                <mat-select [(ngModel)]="selected.state" multiple (openedChange)="onSelectionChange('state', $event)">
                    <!-- <mat-option (click)="toggleSelectAll('state', 'All States')" [value]="'All States'"
                        *ngIf="data.filters?.state?.length">
                        All States
                    </mat-option> -->
                    <mat-option disabled class="no-data" value="" *ngIf="!data.filters?.state?.length">No
                        Data</mat-option>
                    <mat-option *ngFor="let state of data.filters?.state" [value]="state">{{
                        state
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex>
            <mat-form-field>
                <mat-label>{{'Product Line' | translate}}</mat-label>
                <mat-select [(ngModel)]="selected.productline" multiple
                    (openedChange)="onSelectionChange('productline', $event)">
                    <!-- <mat-option (click)="toggleSelectAll('productline', 'All Product Lines')"
                        [value]="'All Product Lines'" *ngIf="data.filters?.productline?.length">
                        All Product Lines
                    </mat-option> -->
                    <mat-option disabled class="no-data" value="" *ngIf="!data.filters?.productline?.length">No
                        Data</mat-option>
                    <mat-option *ngFor="let line of data.filters?.productline" [value]="line">{{
                        line
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex>
            <mat-form-field>
                <mat-label>{{'Line of Business' | translate}}</mat-label>
                <mat-select [(ngModel)]="selected.lob" multiple (openedChange)="onSelectionChange('lob', $event)">
                    <!-- <mat-option (click)="toggleSelectAll('lob', 'All LOBs')" [value]="'All LOBs'"
                        *ngIf="data.filters?.lob?.length">
                        All LOBs
                    </mat-option> -->
                    <mat-option disabled class="no-data" value="" *ngIf="!data.filters?.lob?.length">No
                        Data</mat-option>
                    <mat-option *ngFor="let lob of data.filters?.lob" [value]="lob">{{
                        lob
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex>
            <mat-form-field>
                <mat-label>{{'Date' | translate}}</mat-label>
                <mat-select [formControl]="dateRangeControl">
                    <mat-option value="R12">{{'Rolling 12 (R12)' | translate}}</mat-option>
                    <mat-option value="YTD">{{'Year to Date (YTD)' | translate}}</mat-option>
                    <mat-option value="LYTD">{{'Last Year to Date (LYTD)' | translate}}</mat-option>
                    <mat-option value="CUSTOM">{{'Custom Date Range' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex [hidden]="dateRangeControl.value !== 'CUSTOM'">
            <mat-form-field>
                <mat-label>{{'Enter a date range' | translate}}</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate [formControl]="startDate" [value]="date" placeholder="Start date" />
                    <input matEndDate [formControl]="endDate" [value]="date" placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>{{'Cancel' | translate}}</button>
    <button mat-raised-button color="primary" (click)="applyFilters()"
        [class.disabled]="startDate.invalid || endDate.invalid"
        [disabled]="startDate.invalid || endDate.invalid">{{'Apply' |
        translate}}</button>
</div>