import { Component, OnInit, ChangeDetectorRef, TemplateRef, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { AlertActionEntityDialogComponent, LayoutUtilsService, LoaderService, RequestService } from "src/app/shared";
import { capitalizeFirstLetter, getCSSVariableValue } from "src/app/shared/helpers/functions";
import { MondayService } from "src/app/shared/services/monday.service";
import { PGIService } from "src/app/shared/services/pgi.service";
import { ChartsFilterDialogComponent } from "../charts-filter-dialog/charts-filter-dialog.component";
import { ChartComponent } from "ng-apexcharts";
import moment from "moment";
import { CurrencyPipe, DecimalPipe, PercentPipe } from "@angular/common";
import { MatSelect } from "@angular/material/select";
import { environment } from "src/environments/environment";
import { DeviceDetectorService } from "ngx-device-detector";
import { ChartsService } from "src/app/shared/services/charts.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-book-overview',
    templateUrl: './book-overview.component.html',
    styleUrl: './book-overview.component.scss',
    providers: [CurrencyPipe, PercentPipe, DecimalPipe],
})
export class BookOverviewPageComponent implements OnInit, OnDestroy {
    // agancies: any = [];
    // products: any = [];
    // lobs: any = [];
    // agents: any = [];
    // states: any = [];
    // carriers: any = [];
    chart: any = {};
    baseColor = getCSSVariableValue('--bs-primary');
    secondaryColor = getCSSVariableValue('--bs-second-primary');
    thirdColor = getCSSVariableValue('--bs-third-primary');
    fourColor = getCSSVariableValue('--bs-four-primary');
    labelColor = getCSSVariableValue('--bs-gray-500');
    borderColor = getCSSVariableValue('--bs-gray-200');
    today = new Date();
    currentDate = this.today.toISOString().split('T')[0];
    currentTime = this.today.toTimeString().split(' ')[0];
    currentDateTime = `${this.currentDate.replace(/-/g, '_')}_${this.currentTime.replace(/_/g, '')}`;
    writePremiumChartTitle: string = 'Written Premium by Carrier [TOP 10]';
    pilChartTitle: string = 'PIF by Carrier [TOP 10]';
    form: any = {};
    selectTab: any = 'Auto';
    openDialog = false;
    ChartData: any = {};
    TablesData: any = {};
    // filters: any = {};
    activeFilters: any = {};
    defaultActiveFilters: any = {
        agency_name: 'All Agencies',
        lob: ['All LOBs'],
        state: ['All States'],
        productline: ['All Product Lines'],
        agent: ['All Agents'],
        carrier: ['All Carriers'],
    };
    // selectedPage: string = '';
    detailsData: any = undefined;
    writtenPremiumChart: any = undefined;
    policiesInForceChart: any = undefined;
    growthChart: any = undefined;
    quotesChart: any = undefined;
    isAdmin: boolean = false;
    isAgencyAdmin: boolean = false;
    selectedPoliciesInForceFilter: string = 'personal';
    policiesInForceOptions = [{ name: "PL", value: "personal" }, { name: "CL", value: "Commercial" }, { name: "PL (L/F)", value: "Other PL (Life/Financial)" }, { name: "CL (H/B)", value: "Other CL (Health/Benefits)" }, { name: "Total", value: "All" }];
    selectedWrittenPremiumFilter: string = 'personal';
    selectedWrittenPremiumCarrierFilter: string = 'carrier';
    writtenPremiumOptions = [{ name: "PL", value: "personal" }, { name: "CL", value: "Commercial" }, { name: "PL (L/F)", value: "Other PL (Life/Financial)" }, { name: "CL (H/B)", value: "Other CL (Health/Benefits)" }, { name: "Total", value: "all" }];
    selectedGrowthFilter: string = 'WP';
    growthOptions = [{ name: "WP", value: "WP" }, { name: "PIF", value: "PIF" }];
    quotesOptions = [{ name: "PL", value: "personalLines" }];
    selectedQuoteFilter: string = 'personalLines';
    topTableExpanded: boolean = false;
    growthTableExpanded: boolean = false;
    carrierOptions: any = [{ name: "Carrier", value: "carrier" }, { name: "LOB", value: "lob" }, { name: 'City', value: 'city' }, { name: 'State', value: 'state' }, { name: 'Transaction Type', value: 'Transaction Type' }];
    selectedPILCarrierFilter: string = 'carrier';
    chips: any = [];
    public showFilters: boolean = false;

    private currentUser: any = undefined;
    private subscriptions: any = [];
    private pgiData: any = {};
    public token: string = undefined;
    private email: string = null;
    private isMobile: boolean = false;
    private jobTitle: string = '';
    private agencyId: string = '';

    @ViewChild('writtenPremiumChartSelector', { static: false }) writtenPremiumChartSelector: ChartComponent;
    @ViewChild('policiesInForceSelector', { static: false }) policiesInForceSelector: ChartComponent;
    @ViewChild('growthChartSelector', { static: false }) growthChartSelector: ChartComponent;
    @ViewChild('quotesChartSelector', { static: false }) quotesChartSelector: ChartComponent;
    @ViewChild('chartsDropdown') chartsDropdown: MatSelect;

    constructor(private dialog: MatDialog, public requestService: RequestService,
        private router: Router, private currencyPipe: CurrencyPipe, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService,
        private cdr: ChangeDetectorRef, private percentagePipe: PercentPipe, private decimalPipe: DecimalPipe,
        private mondayService: MondayService,
        private pgiService: PGIService, private chartsService: ChartsService,
        public route: ActivatedRoute, private loaderService: LoaderService, private deviceDetector: DeviceDetectorService
    ) {
        this.isMobile = this.deviceDetector.isMobile();
    }

    ngOnInit(): void {
        this.subscriptions.push(this.route.params.subscribe((params) => {
            // console.log(params);
            if (params['token']) {
                this.token = params['token'];
                // console.log(this.decodeToken(this.token))
                let decodedToken = this.decodeToken(this.token);
                this.email = decodedToken?.email;
                this.agencyId = decodedToken?.agencyId;
                this.jobTitle = decodedToken?.jobTitle || '';
                // for testing
                // this.email = 'jaclyn.schmitz@brightsideins.com';
                this.pgiDataInit(this.email);
            } else {
                this.pgiDataInit(null);
            }
        }));

        this.route.data.subscribe(data => {
            // console.log(data)
            this.pgiService.hideTopMenu.next(data.hideTopMenu);
        });

        this.subscriptions.push(this.requestService.currentUserSubject.subscribe((data: any) => {
            if (data) {
                this.currentUser = data;
                this.isAdmin = this.requestService.isUserRoleAdmin();
                this.isAgencyAdmin = this.requestService.isUserRoleAgencyAdmin();
            }
        }));

        this.subscriptions.push(this.chartsService.refreshFiltersSubject.subscribe((doRefresh: boolean) => {
            if (doRefresh) {
                this.refreshFilter();
            }
        }));

        this.subscriptions.push(this.chartsService.removeFilterSubject.subscribe((filter: any) => {
            if (filter) {
                this.removeFilter(filter);
            }
        }));

        // this.subscriptions.push(this.chartsService.selectedTabSubject.subscribe((tab: string) => {
        //     // this.changeTab(tab);
        //     this.selectedPage = tab;
        // }));

        this.subscriptions.push(this.chartsService.openFiltersSubject.subscribe((doOpen: boolean) => {
            if (doOpen) {
                this.open();
            }
        }));

        this.subscriptions.push(this.chartsService.toggleMoreFilterSubject.subscribe((doOpen: boolean) => {
            this.showFilters = doOpen;
        }));
    }

    ngOnDestroy(): void {
        // this.chartsService.enableFilterSubject.next(false);
        this.subscriptions.forEach(el => el.unsubscribe());
        this.chartsService.chipsSubject.next([]);
        this.chartsService.openFiltersSubject.next(false);
        this.chartsService.toggleMoreFilterSubject.next(false);
    }

    decodeToken(token: any) {
        const payload = token.split('.')[1];
        const decoded = atob(payload);
        return JSON.parse(decoded);
    }

    async pgiDataInit(email: any) {
        this.loaderService.display(true);
        if (email) {
            let CRMuser;

            if (this.jobTitle?.toLowerCase() == 'pgiadmin') {
                CRMuser = {
                    email: email,
                    email0: email,
                    type: '0',
                    newUserType: environment.customKeys.roleAdmin,
                    name: 'PGI Admin',
                    dup__of_type: environment.customKeys.roleAdmin,
                    // link_to_agencies5: 'Premier Group Insurance',
                    mirror_1__1: this.agencyId ? this.agencyId : 'WplIQFgC0iUOXClzDYn6V5BLDABzqHesm7Q7RUZUGpg1',
                    token: this.token,
                };

                this.requestService.currentUser = CRMuser;
                this.requestService.currentUserSubject.next(CRMuser);

                this.loaderService.display(false);
            }
            else {
                //TODO remove this and put it on backend side check with udhay
                this.requestService.setToken('n7m0c2b9z6C9u3w9eliufhfnjkdnbcaakjdhgasdhflhaigkladsglghakoiruegkajsnbnvvjvbv');

                this.mondayService.getAgentByEmail(email).then((user: any) => {
                    if (user && user.status && user.results) {
                        let CRMuser = user.results;
                        if (CRMuser) {
                            CRMuser.email = email;

                            let userType = CRMuser['dup__of_type'];
                            let newUserType = userType;

                            if (this.requestService.isUserRoleAgencyAdmin(userType)) {
                                newUserType = 'Agency Admin';
                                CRMuser.type = '1';
                            } else if (this.jobTitle?.toLowerCase() == 'pgiadmin') {
                                newUserType = environment.customKeys.roleAdmin;
                                CRMuser.type = '0';
                            } else {
                                newUserType = 'Agent';
                                CRMuser.type = '2';
                            }

                            CRMuser.dup__of_type = newUserType;

                            CRMuser.newUserType = newUserType;
                            CRMuser.token = this.token;
                            CRMuser._id = CRMuser.id;

                            this.requestService.currentUser = CRMuser;
                            this.requestService.currentUserSubject.next(CRMuser);
                        }

                        this.loaderService.display(false);
                    }
                });
            }
        }
        // this.getDropDownFilter();
        // this.onSearch();
        // this.cdr.detectChanges();

        // this.loadingService.show();
        // let filtersPromise = this.pgiService.getPGIOverviewFilter().then((pgiFiltersData: any) => {
        //     if (pgiFiltersData && pgiFiltersData.status && pgiFiltersData.results) {
        //         this.filters = {
        //             agency_name: pgiFiltersData.results.filters.Agency,
        //             lob: pgiFiltersData.results.filters.LOB,
        //             state: pgiFiltersData.results.filters.AgencyState,
        //             productline: pgiFiltersData.results.filters.ProductLine,
        //             agent: pgiFiltersData.results.filters.Agent,
        //             carrier: pgiFiltersData.results.filters.CarrierName,
        //         };
        //     }
        // });

        let overviewPromise = this.pgiService.getPGIOverview(this.activeFilters).then((data: any) => {
            if (data && data.status && data.results) {
                this.pgiData = data.results;
                this.dataMapping(this.pgiData);
            }
        });

        Promise.all([overviewPromise]).catch(e => {
            this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + e, 'Dismiss');
        }).finally(() => this.loaderService.display(false));
    }

    dataMapping(data: any) {
        const fixedOrder = ['Personal', 'Commercial', 'Others', 'Other PL (Life/Financial)', 'Other CL (Health/Benefits)', 'Total'];

        // Top Table
        this.TablesData['topData'] = data.top?.map((item: any) => {
            return {
                type: item.type,
                value: item.value != null ? this.currencyPipe.transform(item.value.toFixed(2), 'USD') : '-',
                growth: item.growth != null ? this.percentagePipe.transform(item.growth.toFixed(2)) : '-',
                count: item.count != null ? this.decimalPipe.transform(item.count.toFixed(2)) : '-',
                growth_percentage: item.growth_percentage != null ? this.percentagePipe.transform(item.growth_percentage.toFixed(2)) : '-',
                value2: item.value2 != null ? this.currencyPipe.transform(item.value2.toFixed(2), 'USD') : '-',
                growth2: item.growth2 != null ? this.percentagePipe.transform(item.growth2.toFixed(2)) : '-',
                count2: item.count2 != null ? this.decimalPipe.transform(item.count2.toFixed(2)) : '-',
                growth_percentage2: item.growth_percentage2 != null ? this.percentagePipe.transform(item.growth_percentage2.toFixed(2)) : '-',
                expandable: item.group == 'Other' && item.type != 'Others' ? true : false,
                triggerExpand: item.type == 'Others' ? true : false,
            };
        }).sort((a, b) => fixedOrder.indexOf(a.type) - fixedOrder.indexOf(b.type));


        try {
            this.setWrittenPremiumChart(this.selectedWrittenPremiumFilter, this.selectedWrittenPremiumCarrierFilter, true);
            this.setPoliciesInForceChart(this.selectedPoliciesInForceFilter, this.selectedPILCarrierFilter, true);
            this.setGrowthChart(this.selectedGrowthFilter, true);
            this.setQuoteChart(this.selectedQuoteFilter, true);
        }
        catch (e) {
            console.error(e);
            this.loaderService.display(false);
        }
    }

    // formatMonth(date: string): string {
    //     const year = date.substring(0, 4);
    //     const month = date.substring(4);
    //     const monthInLetters = new Date(`${year}-${month}-01`).toLocaleString(
    //         'default',
    //         {
    //             month: 'short',
    //         }
    //     );
    //     return monthInLetters.substring(0, 3);
    // }
    refreshFilter() {
        this.chips = [];
        this.chartsService.chipsSubject.next([]);
        this.activeFilters = this.defaultActiveFilters;
        this.detailsData = undefined;

        this.selectedPoliciesInForceFilter = 'personal';
        this.selectedWrittenPremiumFilter = 'personal';
        this.selectedWrittenPremiumCarrierFilter = 'carrier';
        this.selectedGrowthFilter = 'wp';
        this.selectedQuoteFilter = 'personalLines';
        this.selectedPILCarrierFilter = 'carrier';

        this.filterPolicesInForce();
        this.filterGrowth();
        this.filterQuotes();
        this.filterWrittenPremium();

        this.pgiDataInit(this.email);
    }

    open() {
        const dialog = this.dialog.open(ChartsFilterDialogComponent, {
            width: 'fit-content',
            minWidth: '40vw',
            disableClose: true,
            data: {
                loggedInUserType: this.requestService.currentUser?.newUserType,
                // filters: this.filters,
                activeFilters: this.activeFilters,
                isAdmin: this.isAdmin,
                isAgencyAdmin: this.isAgencyAdmin,
            }
        });
        dialog.afterClosed().subscribe((data: any) => {
            if (data) {
                this.activeFilters = data;
                this.getChips();
                // console.log(this.activeFilters)
                // console.log(this.chips)
                this.loaderService.display(true);
                this.pgiService.getPGIOverview(this.activeFilters).then((data: any) => {
                    if (data && data.status && data.results) {
                        this.pgiData = data.results;
                        this.dataMapping(this.pgiData);
                    }
                }).catch((e) => {
                    this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + e, 'Dismiss');
                }).finally(() => {
                    this.loaderService.display(false);
                });
            }
        });
    }

    welcomeFilter() {
        this.openDialog = false;
    }

    detectChanges() {
        this.cdr.detectChanges();
    }

    // changeTab(value: string) {
    //     this.chartsDropdown.close();
    //     this.selectedPage = value;
    //     this.router.navigate([value]);
    //     this.detailsData = undefined;
    // }

    // viewDetails(details: any) {
    //     this.selectedPage = details.tab;
    //     this.detailsData = details;
    // }

    // goBack() {
    //     this.detailsData = undefined;
    //     this.selectedPage = 'targeted-opportunities';
    // }

    goToDetails() {
        this.router.navigate(['/book-overview/details']);
    }

    private getPieChart(title: string = '', series = [], labels = this.ChartData.written_premium.labels, type: string, height = 350) {
        let filename = title?.replace(' ', '_').toLowerCase() + '_' + this.currentDateTime;

        return {
            series: series,
            labels: labels,
            chart: {
                fontFamily: 'inherit',
                type: 'pie',
                height: height,
                toolbar: {
                    show: true,
                    // offsetX: 10,
                    // offsetY: height - 50,
                    tools: {
                        download:
                            '  <i style="color: var(--bs-primary) !important; width: 30px; font-size: 17px; vertical-align: middle;" class="fa-solid fs-1 fa-circle-arrow-down p-1"></i>',
                    },
                    export: {
                        csv: {
                            filename: filename,
                        },
                        svg: {
                            filename: filename,
                        },
                        png: {
                            filename: filename,
                        },
                    },
                },
                events: {
                    dataPointSelection: this.navigate.bind(this, type),
                    dataPointMouseEnter: (event) => {
                        event.target.style.cursor = "pointer";
                    }
                }
            },
            theme: {
                monochrome: {
                    enabled: true,
                },
            },

            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'left'
            },
            dataLabels: {
                distributed: true,
                enabled: true,
            },
            stroke: {
                show: false,
                // width: 3,
                // colors: [this.baseColor, this.secondaryColor, this.thirdColor, this.fourColor],
            },
            // title: {
            //     text: 'wefewf',
            //     align: 'left',
            // },
            fill: {
                opacity: 1,
            },

            tooltip: {
                style: {
                    fontSize: '12px',
                },
                y: {
                    formatter: (val: number) => {
                        return this.currencyPipe.transform(val, 'USD', 'symbol', '1.2-2');
                    },
                },
            },
            colors: ['#6ce8f3', '#41b8d6', '#298ab9', '#2f5f99', '#31356d', '#534d85', '#6e74b1', '#8f91d7', '#b1baf4', '#b2d4f9'],
        };
    }

    private getLineChart(title: string = '', series = [], labels = [], height = 350, isSlice = false, isUSD = false) {
        let filename = title?.replace(' ', '_').toLowerCase() + '_' + this.currentDateTime;
        return {
            series: series,
            chart: {
                fontFamily: 'inherit',
                type: 'line',
                stacked: false,
                height: height,
                toolbar: {
                    show: true,
                    // offsetX: 10,
                    // offsetY: height - 20,
                    tools: {
                        download:
                            '  <i style="color: var(--bs-primary) !important; width: 30px; font-size: 17px; vertical-align: middle;" class="fa-solid fs-1 fa-circle-arrow-down p-1"></i>',
                        selection: this.isMobile ? false : true,
                        zoom: this.isMobile ? false : true,
                        zoomin: true,
                        zoomout: true,
                        pan: this.isMobile ? false : true,
                        reset: true,
                    },
                    export: {
                        csv: {
                            filename: filename,
                        },
                        svg: {
                            filename: filename,
                        },
                        png: {
                            filename: filename,
                        },
                    },
                },
            },

            xaxis: {
                categories: labels,
                labels: {
                    formatter: (value) => {
                        if (isSlice && value?.length > 3) {
                            return value.slice(0, 3);
                        }
                        return value;
                    }
                }
            },
            yaxis: {
                // min: 0,
                // max: 1000000,
                labels: {
                    formatter: (value: any) =>
                        value == 0
                            ? 0
                            : value.toLocaleString('en-US', { minimumIntegerDigits: 5 }),

                    style: {
                        colors: this.labelColor,
                        fontSize: '12px',
                    },
                },
            },

            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'left'
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 3,
                colors: [this.baseColor, this.secondaryColor, this.thirdColor, this.fourColor],
            },

            fill: {
                opacity: 1,
            },
            tooltip: {
                x: {
                    show: true,
                    formatter: function (val) {
                        // if (isSlice) {
                        //     return labels[val - 1]
                        // }
                        return labels[val - 1];
                    }
                },
                style: {
                    fontSize: '12px',
                },
                y: {
                    formatter: (val: number) => {
                        if (isUSD)
                            return this.currencyPipe.transform(val, 'USD', 'symbol', '1.2-2');
                        return val;
                    },
                },
            },
            colors: [this.baseColor, this.secondaryColor, this.thirdColor, this.fourColor],
            grid: {
                borderColor: this.borderColor,
                strokeDashArray: 4,
                yaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
        };
    }

    navigate(type, event, chartContext, config) {
        const dataPointIndex = config.dataPointIndex;
        const clickedLabel = config.w.config.labels[dataPointIndex];
        // console.log(`book-overview/details?${type == 'pil' ? this.selectedPILCarrierFilter.toLowerCase().replace(' ', '') : this.selectedWrittenPremiumCarrierFilter.toLowerCase().replace(' ', '')}=${clickedLabel.trim()}`)
        this.router.navigateByUrl(`book-overview/details?${type == 'pil' ? this.selectedPILCarrierFilter.toLowerCase().replace(' ', '') : this.selectedWrittenPremiumCarrierFilter.toLowerCase().replace(' ', '')}=${clickedLabel.trim()}&type=${type == 'pil' ? this.selectedPoliciesInForceFilter : this.selectedWrittenPremiumFilter}`);
    }

    isEmptyObject(obj: any): boolean {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    filterPolicesInForce(firstFilter = this.selectedPoliciesInForceFilter, secondFilter = this.selectedPILCarrierFilter) {
        if (this.policiesInForceSelector) {
            this.setPoliciesInForceChart(firstFilter, secondFilter, false);
            this.policiesInForceSelector.updateOptions({
                series: this.ChartData['policies_in_force'].data,
                labels: this.ChartData['policies_in_force'].labels,
            });
            this.pilChartTitle = `PIF by ${capitalizeFirstLetter(secondFilter)} [TOP 10]`;
        }
    }

    filterWrittenPremium(firstFilter = this.selectedWrittenPremiumFilter, secondFilter = this.selectedWrittenPremiumCarrierFilter) {
        if (this.writtenPremiumChartSelector) {
            this.setWrittenPremiumChart(firstFilter, secondFilter, false);
            this.writtenPremiumChartSelector.updateOptions({
                series: this.ChartData['written_premium'].data,
                labels: this.ChartData['written_premium'].labels,
            });
            this.writePremiumChartTitle = `Written Premium by ${capitalizeFirstLetter(secondFilter)} [TOP 10]`;
        }
    }

    filterGrowth() {
        if (this.growthChartSelector) {
            this.setGrowthChart(this.selectedGrowthFilter, false);
            this.growthChartSelector.updateOptions({
                series: this.ChartData['growth_chart'].data,
                labels: this.ChartData['growth_chart'].labels,
            });
        }
    }

    filterQuotes(value = this.selectedQuoteFilter) {
        if (this.quotesChartSelector) {
            this.setQuoteChart(value, false);
            this.quotesChartSelector.updateOptions({
                series: this.ChartData['quotes_chart'].data,
                labels: this.ChartData['quotes_chart'].labels,
            });
        }
    }

    private setPoliciesInForceChart(firstFilter: string, secondFilter: string, renderChart: boolean = false) {
        let data = this.pgiData.policies_in_force.find(i => i.type.toLowerCase() == firstFilter.toLowerCase() && i.group.toLowerCase() == secondFilter.toLowerCase().replace(' ', ''))?.data;

        this.TablesData['policies_in_force'] = [];
        this.ChartData['policies_in_force'] = { data: [], labels: [] };
        // this.policiesInForceChart = undefined;

        if (data?.length) {
            this.TablesData['policies_in_force'] = data.map(
                (item: any) => {
                    return {
                        company: capitalizeFirstLetter(item.category),
                        value: item.value != null ? this.decimalPipe.transform(item.value) : '-',
                        percent_of_total: item.percent_of_total != null ? this.percentagePipe.transform(item.percent_of_total.toFixed(2)) : '-',
                    };
                }
            );

            this.ChartData['policies_in_force'] = {
                data: data.map((item: any) => item.value),
                labels: data.map((item: any) => capitalizeFirstLetter(item.category)),
            };

            if (renderChart && this.ChartData.policies_in_force.data.length && this.ChartData.policies_in_force.labels.length) {
                this.policiesInForceChart = this.getPieChart('Policies In Force', this.ChartData.policies_in_force.data, this.ChartData.policies_in_force.labels, 'pil');
            }
        }
    }

    private setWrittenPremiumChart(firstFilter: string, secondFilter: string, renderChart: boolean = false) {
        let data = this.pgiData.written_premium.find(i => i.type.toLowerCase() == firstFilter.toLowerCase() && i.group.toLowerCase() == secondFilter.toLowerCase().replace(' ', ''))?.data;

        this.TablesData['written_premium'] = [];
        this.ChartData['written_premium'] = { data: [], labels: [] };
        // this.writtenPremiumChart = undefined;

        if (data?.length) {
            this.TablesData['written_premium'] = data.map(
                (item: any) => {
                    return {
                        company: capitalizeFirstLetter(item.category),
                        value: item.value != null ? this.currencyPipe.transform(item.value, 'USD') : '-',
                        percent_of_total: item.percent_of_total != null ? this.percentagePipe.transform(item.percent_of_total.toFixed(2)) : '-',
                    };
                }
            );

            this.ChartData['written_premium'] = {
                data: data.map((item: any) => item.value),
                labels: data.map((item: any) => capitalizeFirstLetter(item.category)),
            };

            if (renderChart && this.ChartData.written_premium.data.length, this.ChartData.written_premium.labels.length) {
                this.writtenPremiumChart = this.getPieChart('Written premium', this.ChartData.written_premium.data, this.ChartData.written_premium.labels, 'wp');
            }
        }
    }

    private setGrowthChart(filter: string, renderChart: boolean = false) {
        const order = ["personalLines", "commercialLines", "other", "otherPL", "otherCL", "total"];

        this.TablesData['growth'] = [];
        this.ChartData['growth_chart'] = { data: [], labels: [] };
        // this.growthChart = undefined;

        if (this.pgiData.growth[filter.toUpperCase()]?.length) {
            this.TablesData['growth'] = this.pgiData.growth[filter.toUpperCase()]?.map((item: any) => {
                return {
                    ...item,
                    priorYear: item.priorYear != null ? (this.selectedGrowthFilter.toLowerCase() == 'wp' ? this.currencyPipe.transform(item.priorYear, 'USD') : this.decimalPipe.transform(item.priorYear.toFixed(2))) : '-',
                    currentYear: item.currentYear != null ? (this.selectedGrowthFilter.toLowerCase() == 'wp' ? this.currencyPipe.transform(item.currentYear, 'USD') : this.decimalPipe.transform(item.currentYear.toFixed(2))) : '-',
                    yoYChange: item.yoYChange != null ? (this.selectedGrowthFilter.toLowerCase() == 'wp' ? this.currencyPipe.transform(item.yoYChange, 'USD') : this.decimalPipe.transform(item.yoYChange.toFixed(2))) : '-',
                    yoYChangeRaw: item.yoYChange,
                    yoYChangePercentage: item.yoYChangePercentage != null ? this.percentagePipe.transform(item.yoYChangePercentage) : '-',
                    yoYChangePercentageRaw: item.yoYChangePercentage,
                    expandable: item.group == 'Other' && item.label.toLowerCase() != 'other' ? true : false,
                    triggerExpand: item.group?.toLowerCase() == item.label?.toLowerCase() && this.pgiData.growth[filter.toUpperCase()].filter(j => j.group == item.group).length > 1 ? true : false,
                };
            }).sort((a, b) => order.indexOf(a.label) - order.indexOf(b.label));

            let dataChart = this.pgiData.growth_chart;
            if (dataChart?.length) {
                let months = [];
                dataChart[0].data.forEach(data => {
                    if (!months.includes(data.month)) {
                        months.push(moment(data.month, "YYYYMM").format("MMM YYYY"));
                    }
                });

                this.ChartData['growth_chart'] = {
                    data: dataChart.map((item: any) => {
                        return {
                            name: item.label,
                            data: item.data.map((item1: any) => filter == 'wp' ? item1.wp : item1.pif),
                        };
                    }),
                    labels: months,
                };

                if (renderChart && this.ChartData.growth_chart.data.length && this.ChartData.growth_chart.labels.length) {
                    this.growthChart = this.getLineChart('Growth', this.ChartData.growth_chart.data, this.ChartData.growth_chart.labels, 400, false, true);
                }
            }
        }
    }

    private setQuoteChart(filter: string, renderChart: boolean = false) {
        let dataTable = this.pgiData.quotes;

        this.TablesData['quotes'] = [];
        this.ChartData['quotes_chart'] = { data: [], labels: [] };
        // this.quotesChart = undefined;

        if (dataTable?.length) {
            this.TablesData['quotes'] = dataTable[0];
        }

        let dataChart = this.pgiData.quotes_chart;
        if (dataChart?.length) {
            let months = [];
            dataChart[0].data.forEach(data => {
                if (!months.includes(data.month)) {
                    months.push(moment(data.month, "YYYYMM").format("MMM YYYY"));
                }
            });
            // console.log(months)

            this.ChartData['quotes_chart'] = {
                data: dataChart.map((item: any) => {
                    return {
                        name: capitalizeFirstLetter(item.label.replace('_', ' ')),
                        data: item.data.map((item1: any) => item1.value),
                    };
                }),
                labels: months,
            };

            if (renderChart && this.ChartData.quotes_chart.data.length && this.ChartData.quotes_chart.labels.length) {
                this.quotesChart = this.getLineChart('EZLynx Quotes to New Business Policies', this.ChartData.quotes_chart.data, this.ChartData.quotes_chart.labels, 400, false);
            }
        }
    }

    expandTopTable(item: any) {
        if (item.triggerExpand) {
            this.topTableExpanded = !this.topTableExpanded;
        }
    }

    expandGrowth(item: any) {
        if (item.triggerExpand) {
            this.growthTableExpanded = !this.growthTableExpanded;
        }
    }

    private getChips() {
        this.chips = [];
        Object.keys(this.activeFilters).forEach(key => {
            if (this.activeFilters[key]?.length) {
                if (Array.isArray(this.activeFilters[key])) {
                    this.activeFilters[key].forEach(filter => {
                        this.chips.push({ name: filter, value: filter, type: key });
                    });
                }
                else {
                    if (key == 'dateType') {
                        if (this.activeFilters['dateType'].toLowerCase() == 'custom') {
                            this.chips.push({ name: `Custom (${moment(this.activeFilters.startDate).format('YYYY-MM-DD')} to ${moment(this.activeFilters.endDate).format('YYYY-MM-DD')})`, value: this.activeFilters[key], type: key });
                        }
                        else {
                            this.chips.push({ name: this.activeFilters[key], value: this.activeFilters[key], type: key });
                        }
                    }
                    else {
                        if (key != 'agency')
                            this.chips.push({ name: this.activeFilters[key], value: this.activeFilters[key], type: key });
                    }
                }
            }
        });
        this.chartsService.chipsSubject.next(this.chips);
    }

    async removeFilter(filter: any) {
        if (Array.isArray(this.activeFilters[filter.type]))
            this.activeFilters[filter.type] = this.activeFilters[filter.type].filter(i => i != filter.value);
        else {
            this.activeFilters[filter.type] = '';

            if (filter.type == 'dateType') {
                this.activeFilters['startDate'] = '';
                this.activeFilters['endDate'] = '';
            }
        }

        this.getChips();

        this.loaderService.display(true);
        this.pgiService.getPGIOverview(this.activeFilters).then((data: any) => {
            if (data && data.status && data.results) {
                this.pgiData = data.results;
                this.dataMapping(this.pgiData);
            }
        }).catch(e => {
            this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + e, 'Dismiss');
        }).finally(() => this.loaderService.display(false));
    }

    underConstruction() {
        const dialog = this.dialog.open(AlertActionEntityDialogComponent, {
            data: {
                description: 'This feature is under construction.',
                alertSetting: {
                    showCloseButton: true,
                    style: { 'color': '#34577f' },
                    closeButtonStyle: { 'color': 'white', 'background-color': '#34577f' },
                },
                disableClose: true,
                width: 'fit-content'
            }
        });
    }

    toggleMoreFilter() {
        this.showFilters = !this.showFilters;
        this.chartsService.toggleMoreFilterSubject.next(this.showFilters);
    }

    queryPILParams(val) {
        return { [this.selectedPILCarrierFilter.toLowerCase().replace(' ', '')]: val.trim(), type: this.selectedPoliciesInForceFilter };
    }

    queryWPParams(val) {
        return { [this.selectedWrittenPremiumCarrierFilter.toLowerCase().replace(' ', '')]: val.trim(), type: this.selectedWrittenPremiumFilter };
    }
}
