<!-- <app-loading-screen *ngIf="token"></app-loading-screen> -->
<!-- <ng-container *ngIf="selectedPage == 'targeted_opportunities' && !detailsData">
    <app-targeted-opportunities (detailsToView)="viewDetails($event)"></app-targeted-opportunities>
</ng-container> -->

<!-- <ng-container *ngIf="selectedPage == 'targeted-opportunities' && detailsData">
    <app-opportunity-details (goBackEmit)="goBack()" [detailsData]="detailsData"></app-opportunity-details>
</ng-container> -->
<!-- <ng-container *ngIf="selectedPage == 'book-overview'"> -->
<div class="table-top" *ngIf="TablesData?.topData">
    <table>
        <thead>
            <tr>
                <th style="text-align: left;">Line of Business</th>
                <th>Written Premium</th>
                <th>WP Growth</th>
                <th>Policies in Force</th>
                <th>PIF Growth</th>
                <th>New Business WP</th>
                <th>NB WP Growth</th>
                <th>NB PIF</th>
                <th>NB PIF Growth</th>
            </tr>
        </thead>
        <tbody>
            @for (item of TablesData?.topData; track $index) {
            <tr [hidden]="!topTableExpanded && item.expandable" (click)="expandTopTable(item)"
                [ngStyle]="{'cursor': item.triggerExpand ? 'pointer': 'default'}">
                <td [ngStyle]="{'padding-left': item.expandable ? '1vw': ''}">{{ item.type }} <mat-icon
                        style="vertical-align: text-top;font-size: 13pt;" *ngIf="item.triggerExpand">{{topTableExpanded?
                        'keyboard_arrow_up':'keyboard_arrow_down'}}</mat-icon></td>
                <td>{{ item.value }}</td>
                <td>
                    {{ item.growth }}<span *ngIf="item.growth > 0" style="color: green">&nbsp;&#9650;</span><span
                        *ngIf="item.growth < 0" style="color: red">&nbsp;&#9660;</span>
                </td>
                <td>{{ item.count}}</td>
                <td>
                    {{ item.growth_percentage }}<span *ngIf="item.growth_percentage > 0"
                        style="color: green">&nbsp;&#9650;</span><span *ngIf="item.growth_percentage < 0"
                        style="color: red">&nbsp;&#9660;</span>
                </td>
                <td>{{ item.value2 }}</td>
                <td>
                    {{ item.growth2 }}<span *ngIf="item.growth2 > 0" style="color: green">&nbsp;&#9650;</span><span
                        *ngIf="item.growth2 < 0" style="color: red">&nbsp;&#9660;</span>
                </td>
                <td>{{ item.count2 }}</td>
                <td>
                    {{ item.growth_percentage2 }}<span *ngIf="item.growth_percentage2 > 0"
                        style="color: green">&nbsp;&#9650;</span><span *ngIf="item.growth_percentage2 < 0"
                        style="color: red">&nbsp;&#9660;</span>
                </td>
            </tr>
            }
        </tbody>
    </table>
</div>
<div fxLayout="row" fxLayout.md="column" fxLayoutGap="1vw" *ngIf="!isEmptyObject(TablesData)" class="row">
    <div fxFlex class="section" fxLayout="column">
        <div fxLayout="row" fxLayout.md="column" fxGrow="0" fxLayoutGap="1vw">
            <span fxFlex class="title" fxGrow="0">{{'Written Premium' | translate }}</span>
            <select fxFlex fxGrow="0" [(ngModel)]="selectedWrittenPremiumFilter"
                (ngModelChange)="filterWrittenPremium()">
                @for (item of writtenPremiumOptions; track $index){
                <option [value]="item.value">{{item.name}}</option>
                }
            </select>
            <select fxFlex fxGrow="0" [(ngModel)]="selectedWrittenPremiumCarrierFilter"
                (ngModelChange)="filterWrittenPremium()">
                @for (item of carrierOptions; track $index){
                <option [value]="item.value">{{item.name}}</option>
                }
            </select>
            <div fxFlex class="details" fxLayoutAlign="end">
                <span (click)="goToDetails()">{{'View Details' | translate }}</span>
            </div>
        </div>
        <div class="table-top" fxFlex>
            <div *ngIf="!TablesData?.written_premium?.length" class="no-data">
                {{'No data matching your query.' | translate}}
            </div>
            <table *ngIf="TablesData?.written_premium?.length">
                <thead>
                    <tr>
                        <th style="text-align: left;">{{selectedWrittenPremiumCarrierFilter | translate}}</th>
                        <th style="width: fit-content;">WP</th>
                        <th style="width: fit-content;">Percent of total</th>
                    </tr>
                </thead>
                <tbody>
                    @for (item of TablesData?.written_premium; track $index) {
                    <tr>
                        <td><span style="cursor: pointer;" [routerLink]="['book-overview/details']"
                                [queryParams]="queryWPParams(item.company)">{{ item.company }}</span></td>
                        <td>{{ item.value }}</td>
                        <td>{{ item.percent_of_total }}</td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
        <div fxLayoutAlign="center end" fxFlex fxGrow="1" class="chart">
            <div *ngIf="!ChartData.written_premium?.data?.length" class="no-data">
                {{'No data matching your query.' | translate}}
            </div>
            <apx-chart [hidden]="!ChartData.written_premium?.data?.length" *ngIf="writtenPremiumChart?.series"
                #writtenPremiumChartSelector [series]="writtenPremiumChart.series" [chart]="writtenPremiumChart.chart"
                [xaxis]="writtenPremiumChart.xaxis" [labels]="writtenPremiumChart.labels"
                [dataLabels]="writtenPremiumChart.dataLabels" [stroke]="writtenPremiumChart.stroke"
                [legend]="writtenPremiumChart.legend" [fill]="writtenPremiumChart.fill"
                [tooltip]="writtenPremiumChart.tooltip" [colors]="writtenPremiumChart.colors"
                [markers]="writtenPremiumChart.markers" [plotOptions]="writtenPremiumChart.plotOptions"></apx-chart>
        </div>
        <div *ngIf="ChartData.written_premium?.data?.length" fxFlex class="chart-title" fxGrow="0">
            {{writePremiumChartTitle}}
        </div>
    </div>
    <div fxFlex class="section" fxLayout="column">
        <div fxLayout="row" fxLayout.md="column" fxFlex fxGrow="0" fxLayoutGap="1vw">
            <span fxFlex class="title" fxGrow="0">{{'Policies In Force' | translate }}</span>
            <select fxFlex fxGrow="0" [(ngModel)]="selectedPoliciesInForceFilter"
                (ngModelChange)="filterPolicesInForce()">
                @for (item of policiesInForceOptions; track $index){
                <option [value]="item.value">{{item.name}}</option>
                }
            </select>
            <select fxFlex fxGrow="0" [(ngModel)]="selectedPILCarrierFilter" (ngModelChange)="filterPolicesInForce()">
                @for (item of carrierOptions; track $index){
                <option [value]="item.value">{{item.name}}</option>
                }
            </select>
            <div fxFlex class="details" fxLayoutAlign="end">
                <span (click)="goToDetails()">{{'View Details' | translate }}</span>
            </div>
        </div>
        <div class="table-top" fxFlex>
            <div *ngIf="!TablesData?.policies_in_force?.length" class="no-data">
                {{'No data matching your query.' | translate}}
            </div>
            <table *ngIf="TablesData?.policies_in_force?.length">
                <thead>
                    <tr>
                        <th style="text-align: left;">{{selectedPILCarrierFilter | translate}}</th>
                        <th style="width: fit-content;">PIF</th>
                        <th style="width: fit-content;">Percent of Total</th>
                    </tr>
                </thead>
                <tbody>
                    @for (item of TablesData?.policies_in_force; track $index) {
                    <tr>
                        <td><span style="cursor: pointer;" [routerLink]="['book-overview/details']"
                                [queryParams]="queryPILParams(item.company)">{{ item.company }}</span>
                        </td>
                        <td>{{ item.value }}</td>
                        <td>{{ item.percent_of_total }}</td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
        <div fxLayoutAlign="center end" fxFlex fxGrow="1" class="chart">
            <div *ngIf="!ChartData.policies_in_force?.data?.length" class="no-data">
                {{'No data matching your query.' | translate}}
            </div>
            <apx-chart [hidden]="!ChartData.policies_in_force?.data?.length" *ngIf="policiesInForceChart?.series"
                #policiesInForceSelector [series]="policiesInForceChart.series" [chart]="policiesInForceChart.chart"
                [xaxis]="policiesInForceChart.xaxis" [labels]="policiesInForceChart.labels"
                [dataLabels]="policiesInForceChart.dataLabels" [stroke]="policiesInForceChart.stroke"
                [legend]="policiesInForceChart.legend" [fill]="policiesInForceChart.fill"
                [tooltip]="policiesInForceChart.tooltip" [colors]="policiesInForceChart.colors"
                [markers]="policiesInForceChart.markers" [plotOptions]="policiesInForceChart.plotOptions"></apx-chart>
        </div>
        <div fxFlex *ngIf="ChartData.policies_in_force?.data?.length" class="chart-title" fxGrow="0">
            {{pilChartTitle}}
        </div>
    </div>
</div>
<div fxLayout="row" fxLayout.md="column" fxLayoutGap="1vw" *ngIf="!isEmptyObject(TablesData)" class="row">
    <div fxFlex class="section" fxLayout="column">
        <div fxLayout="row" fxLayout.md="column" fxGrow="0" fxLayoutGap="1vw">
            <span fxFlex class="title" fxGrow="0">{{'Growth' | translate }}</span>
            <select fxFlex fxGrow="0" [(ngModel)]="selectedGrowthFilter" (ngModelChange)="filterGrowth()">
                @for (item of growthOptions; track $index){
                <option [value]="item.value">{{item.name}}</option>
                }
            </select>
            <div fxFlex class="details" fxLayoutAlign="end">
                <span (click)="underConstruction()">{{'View Details' | translate }}</span>
            </div>
        </div>
        <div class="table-top" fxFlex>
            <div *ngIf="!TablesData?.growth?.length" class="no-data">
                {{'No data matching your query.' | translate}}
            </div>
            <table *ngIf="TablesData?.growth?.length">
                <thead>
                    <tr>
                        <th style="text-align: left;">{{'Line of Business' | translate}}</th>
                        <th>{{selectedGrowthFilter}}</th>
                        <th>{{'Prior Year' | translate}} {{selectedGrowthFilter}}</th>
                        <th>{{'YoY Change' | translate}}</th>
                        <th>{{'YoY % Change' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    @for (row of TablesData.growth; track
                    $index) {
                    <tr [hidden]="!growthTableExpanded && row.expandable" (click)="expandGrowth(row)"
                        [ngStyle]="{'cursor': row.triggerExpand ? 'pointer': 'default'}">
                        <td [ngStyle]="{'padding-left': row.expandable ? '1vw': ''}">{{ row.label | translate}}
                            <mat-icon style="vertical-align: text-top;font-size: 13pt;"
                                *ngIf="row.triggerExpand">{{growthTableExpanded?
                                'keyboard_arrow_up':'keyboard_arrow_down'}}</mat-icon>
                        </td>
                        <td>{{ row.currentYear }}</td>
                        <td>{{ row.priorYear }}</td>
                        <td>
                            {{ row.yoYChange }}<span *ngIf="row.yoYChangeRaw > 0"
                                style="color: green">&nbsp;&#9650;</span><span *ngIf="row.yoYChangeRaw < 0"
                                style="color: red">&nbsp;&#9660;</span>
                        </td>
                        <td>
                            {{ row.yoYChangePercentage }}<span *ngIf="row.yoYChangePercentageRaw > 0"
                                style="color: green">&nbsp;&#9650;</span><span *ngIf="row.yoYChangePercentageRaw < 0"
                                style="color: red">&nbsp;&#9660;</span>
                        </td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
        <div fxLayoutAlign="center end" fxFlex fxGrow="1" class="chart">
            <div *ngIf="!ChartData.growth_chart?.data?.length" class="no-data">
                {{'No data matching your query.' | translate}}
            </div>
            <apx-chart [hidden]="!ChartData.growth_chart?.data?.length" *ngIf="growthChart?.series" #growthChartSelector
                [series]="growthChart.series" [chart]="growthChart.chart" [xaxis]="growthChart.xaxis"
                [yaxis]="growthChart.yaxis" [dataLabels]="growthChart.dataLabels" [stroke]="growthChart.stroke"
                [legend]="growthChart.legend" [fill]="growthChart.fill" [tooltip]="growthChart.tooltip"
                [colors]="growthChart.colors"></apx-chart>
        </div>
    </div>
    <div fxFlex class="section" fxLayout="column">
        <div fxLayout="row" fxLayout.md="column" fxFlex fxGrow="0" fxLayoutGap="1vw">
            <span fxFlex class="title" fxGrow="0"> {{'EZLynx Quotes to New Business Policies' | translate }}</span>
            <select fxFlex fxGrow="0" [(ngModel)]="selectedQuoteFilter" (ngModelChange)="filterQuotes($event)">
                @for (item of quotesOptions; track $index){
                <option [value]="item.value">{{item.name}}</option>
                }
            </select>
            <div fxFlex class="details" fxLayoutAlign="end">
                <span (click)="underConstruction()">{{'View Details' | translate }}</span>
            </div>
        </div>
        <div class="table-top" fxFlex>
            <div *ngIf="!TablesData?.quotes" class="no-data">
                {{'No data matching your query.' | translate}}
            </div>
            <table *ngIf="TablesData?.quotes">
                <tbody>
                    <tr>
                        <td>{{'Quote Count' | translate}}</td>
                        <td>{{TablesData.quotes.quoteCount}}</td>
                    </tr>
                    <tr>
                        <td>{{'New Business Count' | translate}}</td>
                        <td>{{TablesData.quotes.newBusinessCount}}</td>
                    </tr>
                    <tr>
                        <td>{{'Close Ratio' | translate}}</td>
                        <td>{{TablesData.quotes.closeRatio * 100}}%</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div fxLayoutAlign="center end" fxFlex fxGrow="1" class="chart">
            <div *ngIf="!ChartData.quotes_chart?.data?.length" class="no-data">
                {{'No data matching your query.' | translate}}
            </div>
            <apx-chart [hidden]="!ChartData.quotes_chart?.data?.length" *ngIf="quotesChart?.series" #quotesChartSelector
                [series]="quotesChart.series" [chart]="quotesChart.chart" [xaxis]="quotesChart.xaxis"
                [dataLabels]="quotesChart.dataLabels" [stroke]="quotesChart.stroke" [legend]="quotesChart.legend"
                [fill]="quotesChart.fill" [tooltip]="quotesChart.tooltip" [colors]="quotesChart.colors"></apx-chart>
        </div>
    </div>
</div>

<div class="filters-backdrop" *ngIf="showFilters">
    <div class="more-filters" fxLayout="column" fxLayoutGap="3vh">
        <div class="title" fxGrow="0" fxFlex>{{'Selected Filters' | translate}}</div>
        <div fxFlex fxGrow="1" class="chips">
            <mat-chip-set fxLayout="row wrap" fxLayoutGap="0.5vw">
                @for (item of chips; track $index) {
                <mat-chip fxFlex fxGrow="0">
                    <mat-icon leadingIcon class="remove-filter" (click)="removeFilter(item)">close</mat-icon>
                    {{item.name}}
                </mat-chip>
                }
            </mat-chip-set>
        </div>
        <div fxFlex fxLayoutAlign="end" fxGrow="0">
            <button color="primary" mat-raised-button (click)="toggleMoreFilter()">{{'Close' | translate}}</button>
        </div>
    </div>
</div>